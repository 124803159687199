import React from 'react'
import { EventLogTypes } from 'util/enums'

const badgeColors = {
  userRoles: {
    admin: 'bg-lightblue',
    analyst: 'bg-orange',
    dpo: 'bg-yellow',
    viewer: 'bg-primary',
    owner: 'bg-purple'
  },
  documentTypes: {
    privacy: 'bg-purple',
    terms: 'bg-orange',
    cookies: 'bg-lightblue'
  },
  consentLog: {
    [EventLogTypes.FirstVisit]: 'bg-yellow',
    [EventLogTypes.AcceptAll]: 'bg-primary',
    [EventLogTypes.DeleteRequest]: 'bg-pink',
    [EventLogTypes.RejectAll]: 'bg-pink',
    [EventLogTypes.DownloadRequest]: 'bg-orange',
    [EventLogTypes.DoNotSellDat]: 'bg-orange',
    [EventLogTypes.AutomatedDecisionReview]: 'bg-orange',
    [EventLogTypes.ConsentRefusal]: 'bg-orange',
    [EventLogTypes.ConsentRevocation]: 'bg-orange',
    [EventLogTypes.DataPortability]: 'bg-orange',
    [EventLogTypes.DataRemoval]: 'bg-orange',
    [EventLogTypes.DataAnonymization]: 'bg-orange',
    [EventLogTypes.DataSharingInformation]: 'bg-orange',
    [EventLogTypes.DataCorrection]: 'bg-orange',
    [EventLogTypes.DataAccess]: 'bg-orange',
    [EventLogTypes.DataExistence]: 'bg-orange'
  }
}

export type BadgePurpose = keyof typeof badgeColors

interface BadgeProps {
  purpose: BadgePurpose
  type: string
  children: string
}

const getBackgroundColor = <P extends BadgePurpose>(
  purpose: P,
  type: string
) => {
  const colors = badgeColors[purpose]
  if (colors && type in colors) {
    return colors[type as keyof typeof colors]
  }

  return 'bg-gray-500'
}

export const Badge = ({ purpose, type, children }: BadgeProps) => {
  const backgroundColor = getBackgroundColor(purpose, type)

  return (
    <span
      className={`${backgroundColor} dark:text-black font-bold rounded-sm px-1 text-xs h-5 w-fit text-white uppercase whitespace-nowrap`}
    >
      {children}
    </span>
  )
}
