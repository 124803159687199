import React, { useMemo } from 'react'
import { BsImageAlt } from 'react-icons/bs'
import {
  HiOutlineThumbUp,
  HiOutlineThumbDown,
  HiOutlineClipboardCheck
} from 'react-icons/hi'
import { FiSettings } from 'react-icons/fi'
import { DiCode } from 'react-icons/di'
import { RiChatCheckLine } from 'react-icons/ri'
import { VscKey } from 'react-icons/vsc'
import { motion } from 'framer-motion'
import { Link, Tooltip } from 'components'
import StatusBadge from '../../../shared/badge/status-badge'
import { classNames } from 'util/shared'
import { useTranslation } from 'react-i18next'
import { IoExitOutline } from 'react-icons/io5'
import DisclaimerEditDropdown from '../../../shared/dropdown/disclaimer-edit-dropdown'
import { formatNumber } from 'util/format'
import { useStorage } from 'contexts/storage-context'
import { DisclaimerInstalledStatusColor } from 'models/disclaimer'

const easing = [0.6, -0.05, 0.01, 0.99]
const fadeInUp = {
  initial: {
    y: 10,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: easing
    }
  }
}

export default function DisclaimerCardList({
  index,
  disclaimerData,
  refetch
}: any) {
  const { t } = useTranslation()
  const { activeOrganizationDetails } = useStorage()

  const hasPrivacyPolicy = disclaimerData?.disclaimer_documents?.[0]
    ?.documentMasterByDocumentMasterPrivacyId
    ? 1
    : 0
  const hasCookiePolicy = disclaimerData?.disclaimer_documents?.[0]
    ?.document_master
    ? 1
    : 0
  const hasTermsPolicy = disclaimerData?.disclaimer_documents?.[0]
    ?.documentMasterByDocumentMasterTermsId
    ? 1
    : 0

  const hasDocuments =
    hasPrivacyPolicy + hasCookiePolicy + hasTermsPolicy === 3
      ? 'completed'
      : hasPrivacyPolicy + hasCookiePolicy + hasTermsPolicy > 0
      ? 'partial_completed'
      : 'not_completed'

  const disclaimerInstalledStatus = useMemo(() => {
    const { disclaimer_websites } = disclaimerData
    const allDisclaimersInstalled = disclaimer_websites.length
    let disclaimerInstalled = 0
    let disclaimerNotInstalled = 0
    for (const { disclaimer_installed } of disclaimer_websites) {
      if (disclaimer_installed) disclaimerInstalled++
      else disclaimerNotInstalled++
    }

    if (disclaimerInstalled === allDisclaimersInstalled) {
      return 'completed'
    } else if (disclaimerNotInstalled === allDisclaimersInstalled) {
      return 'not_completed'
    } else {
      return 'partial_completed'
    }
  }, [disclaimerData])

  const statusColorMap: DisclaimerInstalledStatusColor = {
    completed: 'bg-primary text-white',
    partial_completed: 'bg-transparent border border-yellow text-yellow',
    not_completed: 'bg-transparent border border-pink text-pink'
  }

  const items = [
    {
      configs: [
        {
          dataTestId: 'styleStep',
          icon: <RiChatCheckLine size={16} />,
          name: t('org.disclaimers.style'),
          tooltip: t('org.disclaimers.style-tooltip'),
          pathname: 'style',
          status:
            disclaimerData?.disclaimer_versions[0]?.disclaimer_style_version ===
            0
              ? 'not_completed'
              : 'completed'
        }
      ]
    },

    {
      tourStep: 'documents-step',
      configs: [
        {
          dataTestId: 'termsStep',
          icon: <HiOutlineClipboardCheck size={18} />,
          name: t('org.disclaimers.documents'),
          tooltip: t('org.disclaimers.documents-tooltip'),
          pathname: 'documents',
          status: hasDocuments
        },
        activeOrganizationDetails?.migrated_to_optout_v2
          ? {
              dataTestId: 'privacyPageStep',
              icon: <IoExitOutline size={18} />,
              name: t('org.disclaimers.privacyPage'),
              tooltip: t('org.disclaimers.privacyPage-tooltip'),
              pathname: 'requests-page',
              status:
                disclaimerData?.disclaimer_versions[0]?.request_page_version ===
                0
                  ? 'not_completed'
                  : 'completed'
            }
          : {
              dataTestId: 'optoutStep',
              icon: <IoExitOutline size={18} />,
              name: t('org.disclaimers.optout'),
              tooltip: t('org.disclaimers.optout-tooltip'),
              pathname: 'opt-out',
              status:
                disclaimerData?.disclaimer_versions[0]?.opt_out_version === 0
                  ? 'not_completed'
                  : 'completed'
            }
      ]
    },

    {
      tourStep: 'tag-step',
      configs: [
        {
          dataTestId: 'tagStep',
          icon: <VscKey size={18} />,
          name: t('org.disclaimers.installation'),
          tooltip: t('org.disclaimers.installation-tooltip'),
          pathname: 'installation',
          status: disclaimerInstalledStatus
        }
      ]
    },
    {
      tourStep: 'scan-step',
      configs: [
        {
          dataTestId: 'scanStep',
          icon: <DiCode size={24} />,
          name: t('org.disclaimers.scan'),
          tooltip: t('org.disclaimers.scan-tooltip'),
          pathname: 'tags',
          status:
            disclaimerData?.disclaimer_versions?.[0]?.tags_version === 0
              ? 'not_completed'
              : 'completed'
        }
      ]
    }
  ]

  return (
    <motion.div
      exit={{ opacity: 0 }}
      variants={fadeInUp}
      key={index}
      data-tour="intro-step"
      data-test-id={'disclaimerCard'}
      className="w-full max-w-100 border py-3 px-3 rounded-lg dark:border-gray-400 border-gray-200 hover:border-primary duration-200 flex-1"
    >
      <div className="flex items-center gap-4">
        <a
          className="flex items-center gap-2 flex-1 truncate"
          href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}`}
        >
          <div className="w-9 h-9 rounded-full border border-gray-200 dark:bg-gray text-primary-400 flex items-center justify-center">
            <BsImageAlt />
          </div>

          <div className="flex-1 truncate">
            <div className="dark:text-primary-400 truncate font-bold">
              {disclaimerData?.pathname}
            </div>
            <div className="dark:text-gray-300 truncate">
              {disclaimerData?.disclaimer_websites?.map(
                (domain: any, index: number) => {
                  return `${domain?.url}${
                    index ===
                    Object.keys(disclaimerData?.disclaimer_websites)?.length - 1
                      ? ''
                      : ', '
                  }`
                }
              )}
            </div>
          </div>
        </a>

        <div
          data-tour="initialConfiguration-step"
          className="flex gap-2 xl:gap-4"
        >
          {items?.map((item, index) => {
            return (
              <div
                className="flex gap-2 xl:gap-4"
                data-tour={item.tourStep}
                key={index}
              >
                {item.configs.map((conf, index) => {
                  return (
                    <a
                      data-test-id={conf.dataTestId}
                      className={`step-${index}`}
                      key={index}
                      href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}/${conf.pathname}`}
                    >
                      <Tooltip size="Auto" content={conf.tooltip}>
                        <div
                          data-delay-show="40"
                          key={index}
                          className="flex flex-col justify-center dark:text-white items-center cursor-pointer opacity-80 hover:opacity-100 duration-200"
                        >
                          <div
                            className={classNames(
                              'w-8 h-8  flex justify-center items-center rounded-full mr-1 ml-1',
                              statusColorMap[conf.status]
                            )}
                          >
                            {conf.icon}
                          </div>
                          <div className="text-xs hidden truncate lg:block">
                            {conf.name}
                          </div>
                        </div>
                      </Tooltip>
                    </a>
                  )
                })}
              </div>
            )
          })}
        </div>

        <Link
          href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}`}
        >
          <div className="flex items-center justify-center gap- ">
            <div className="text-primary-400 flex flex-col items-center w-[90px]">
              <span className="text-gray-200">Opt-in</span>
              <div className="flex gap-2 items-center">
                <HiOutlineThumbUp />
                <span>+ {formatNumber(disclaimerData?.metrics?.opt_ins)}</span>
              </div>
            </div>
            <div className="text-primary-400 flex flex-col items-center w-[90px]">
              <span className="text-gray-200">Opt-out</span>
              <div className="flex gap-2 items-center">
                <HiOutlineThumbDown style={{ color: '#EE447B' }} />
                <span style={{ color: '#EE447B' }}>
                  - {formatNumber(disclaimerData?.metrics?.opt_out)}
                </span>
              </div>
            </div>
          </div>
        </Link>

        <div className="dark:text-white items-center md:hidden xl:flex w-[100px] truncate">
          <StatusBadge
            status={disclaimerData?.disclaimer_status?.name}
          ></StatusBadge>
          <div className="ml-1 capitalize">
            {t(`org.disclaimers.${disclaimerData?.disclaimer_status?.name}`)}
          </div>
        </div>

        <DisclaimerEditDropdown
          availableItems={[
            'configure',
            'edit',
            'replicate',
            'transfer',
            'copyToClipboard',
            'block',
            'delete'
          ]}
          refetch={refetch}
          disclaimerData={disclaimerData}
          position="left"
        >
          <div className="border flex justify-center items-center w-8 h-8 border-primary-400 rounded text-primary-400">
            <FiSettings size={18} />
          </div>
        </DisclaimerEditDropdown>
      </div>
    </motion.div>
  )
}
