import { createContext, ReactNode, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import moment from 'moment'

import { EXPORT_AUDIT_LOGS, LIST_AUDIT_LOGS } from 'queries/audit'

export interface RawAuditLogProps {
  list_audit_logs: { data: { logs: Array<AuditLogProps> } }
}

export interface AuditLogProps {
  id: string
  action: string
  success: string
  organization: string
  userEmail: string
  userRole: string
  version: number
  date: string
  dataIn: string
  dataOut: string
}

export interface DisclaimerSelectedsProps {
  name: string
  value: string
}

interface AuditContextProps {
  loading: boolean
  auditLogs: Array<AuditLogProps>
  startDate: Date | null
  endDate: Date | null
  disclaimerSelecteds: DisclaimerSelectedsProps[]
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setAuditLogs: React.Dispatch<React.SetStateAction<AuditLogProps[]>>
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>
  setDisclaimerSelecteds: React.Dispatch<
    React.SetStateAction<DisclaimerSelectedsProps[]>
  >
  getListAuditLogs: (
    activeOrganizationId: string[],
    lastDate?: string | null | undefined
  ) => Promise<AuditLogProps[]>
  getInitialListAuditLogs: (
    activeOrganizationId: string[]
  ) => Promise<AuditLogProps[]>
  downloadAuditLogs: (
    activeOrganizationId: string[],
    endDate: Date,
    startDate: Date,
    disclaimerSelecteds?: DisclaimerSelectedsProps[]
  ) => Promise<{ link: string; entriesCount: number }>
  getActionLabel(action: string): string
}

interface AuditProviderProps {
  children: ReactNode
}

export const AuditContext = createContext({} as AuditContextProps)

export function AuditProvider({ children }: AuditProviderProps) {
  const { t } = useTranslation()

  const initialEndDate = new Date(new Date(Date.now()))
  const initialStartDate = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  )

  const [loading, setLoading] = useState(true)
  const [auditLogs, setAuditLogs] = useState<AuditLogProps[]>([])
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate)
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate)
  const [disclaimerSelecteds, setDisclaimerSelecteds] = useState<
    DisclaimerSelectedsProps[]
  >([])

  const [listAuditLogs] = useMutation<RawAuditLogProps>(LIST_AUDIT_LOGS)
  const [exportAuditLogs] = useMutation(EXPORT_AUDIT_LOGS)

  async function getListAuditLogs(
    activeOrganizationId: string[],
    lastDate: string | null | undefined = null
  ) {
    const disclaimerIds = disclaimerSelecteds
      ? disclaimerSelecteds.map(({ value }) => value)
      : []

    const response = await listAuditLogs({
      variables: {
        endDate: moment(endDate).format('YYYY-MM-DD[T23:59]'),
        startDate: moment(startDate).format('YYYY-MM-DD[T00:00]'),
        lastDate,
        disclaimerIds: disclaimerIds.length ? disclaimerIds : null,
        organizationIds: disclaimerIds.length ? null : activeOrganizationId,
        limit: 10
      }
    })

    return response?.data?.list_audit_logs?.data?.logs ?? []
  }

  async function getInitialListAuditLogs(activeOrganizationId: string[]) {
    const response = await listAuditLogs({
      variables: {
        endDate: moment(initialEndDate).format('YYYY-MM-DD[T23:59]'),
        startDate: moment(initialStartDate).format('YYYY-MM-DD[T00:00]'),
        lastDate: null,
        disclaimerIds: null,
        organizationIds: activeOrganizationId,
        limit: 50
      }
    })

    return response?.data?.list_audit_logs?.data?.logs ?? []
  }

  async function downloadAuditLogs(
    activeOrganizationId: string[],
    endDate: Date,
    startDate: Date,
    disclaimerSelecteds?: DisclaimerSelectedsProps[]
  ) {
    const disclaimerIds = disclaimerSelecteds
      ? disclaimerSelecteds.map(({ value }) => value)
      : []

    const result = await exportAuditLogs({
      variables: {
        endDate: moment(endDate).format('YYYY-MM-DD[T23:59]'),
        startDate: moment(startDate).format('YYYY-MM-DD[T00:00]'),
        disclaimerIds: disclaimerIds.length ? disclaimerIds : null,
        organizationIds: disclaimerIds.length ? null : activeOrganizationId
      }
    })

    return result?.data?.export_audit_logs?.data
  }

  function getActionLabel(action: string) {
    const actions = {
      editStyle: t('audit.actions.editStyle'),
      scan: t('audit.actions.scan'),
      inviteMember: t('audit.actions.inviteMember'),
      editMember: t('audit.actions.editMember'),
      deleteMember: t('audit.actions.deleteMember'),
      deleteOrganization: t('audit.actions.deleteOrganization'),
      transferOrganization: t('audit.actions.transferOrganization'),
      setModulesToUpdateConsent: t('audit.actions.setModulesToUpdateConsent'),
      createDisclaimer: t('audit.actions.createDisclaimer'),
      deleteDisclaimer: t('audit.actions.deleteDisclaimer'),
      transferDisclaimer: t('audit.actions.transferDisclaimer'),
      editDisclaimer: t('audit.actions.editDisclaimer'),
      editDisclaimerTexts: t('audit.actions.editDisclaimerTexts'),
      editStyleV2: t('audit.actions.editStyleV2'),
      editOptOut: t('audit.actions.editOptOut'),
      updateDisclaimerOptions: t('audit.actions.updateDisclaimerOptions'),
      editPolicy: t('audit.actions.editPolicy'),
      downloadScanReport: t('audit.actions.downloadScanReport'),
      addScan: t('audit.actions.addScan'),
      addEditScanAuth: t('audit.actions.addEditScanAuth'),
      listScanAuth: t('audit.actions.listScanAuth'),
      removeScanAuth: t('audit.actions.removeScanAuth'),
      saveAndPublishTags: t('audit.actions.saveAndPublishTags'),
      scheduleScan: t('audit.actions.scheduleScan'),
      scheduleConsentReport: t('audit.actions.scheduleConsentReport'),
      cancelScheduledScan: t('audit.actions.cancelScheduledScan'),
      editTerms: t('audit.actions.editTerms'),
      searchConsents: t('audit.actions.searchConsents'),
      listConsents: t('audit.actions.listConsents'),
      addReportEvent: t('audit.actions.addReportEvent'),
      unblockDisclaimer: t('audit.actions.unblockDisclaimer'),
      blockDisclaimer: t('audit.actions.blockDisclaimer'),
      restoreTerms: t('audit.actions.restoreTerms'),
      expireConsents: t('audit.actions.expireConsents'),
      restorePolicyVersion: t('audit.actions.restorePolicyVersion'),
      createOrganization: t('audit.actions.createOrganization'),
      exportAuditLog: t('audit.actions.exportAuditLog'),
      listAuditLog: t('audit.actions.listAuditLog'),
      createWebhook: t('audit.actions.createWebhook'),
      updateWebhook: t('audit.actions.updateWebhook'),
      deleteWebhook: t('audit.actions.deleteWebhook'),
      cloneDisclaimer: t('audit.actions.cloneDisclaimer'),
      editTagSources: t('audit.actions.editTagSources'),
      scheduleScanInstall: t('audit.actions.scheduleScanInstall'),
      changeVendorIds: t('audit.actions.changeVendorIds'),
      generatePrivacyDocument: t('audit.actions.generatePrivacyDocument'),
      createCookieDocument: t('audit.actions.createCookieDocument'),
      deleteDocument: t('audit.actions.deleteDocument'),
      addEditPrivacyDocument: t('audit.actions.addEditPrivacyDocument'),
      addEditCookieDocument: t('audit.actions.addEditCookieDocument'),
      editInjectorVersion: t('audit.actions.editInjectorVersion'),
      addEditTermsDocument: t('audit.actions.addEditTermsDocument'),
      linkDocumentToDisclaimer: t('audit.actions.linkDocumentToDisclaimer'),
      restoreDocumentVersion: t('audit.actions.restoreDocumentVersion'),
      updateCookieDocument: t('audit.actions.updateCookieDocument'),
      updateDocumentVersion: t('audit.actions.updateDocumentVersion'),
      deleteLanguageFromDocument: t('audit.actions.deleteLanguageFromDocument'),
      addEditRequestsPage: t('audit.actions.addEditRequestsPage'),
      deleteRequestsPage: t('audit.actions.deleteRequestsPage'),
      linkRequestsPageToDisclaimer: t(
        'audit.actions.linkRequestsPageToDisclaimer'
      ),
      addRequestLinksToDisclaimer: t(
        'audit.actions.addRequestLinksToDisclaimer'
      ),
      restoreRequestPageDisclaimerVersion: t(
        'audit.actions.restoreRequestPageDisclaimerVersion'
      ),
      restoreRequestPageVersion: t('audit.actions.restoreRequestPageVersion')
    }

    return actions[action as keyof typeof actions] || action
  }

  return (
    <AuditContext.Provider
      value={{
        loading,
        auditLogs,
        startDate,
        endDate,
        disclaimerSelecteds,
        setLoading,
        setAuditLogs,
        setStartDate,
        setEndDate,
        setDisclaimerSelecteds,
        getListAuditLogs,
        getInitialListAuditLogs,
        downloadAuditLogs,
        getActionLabel
      }}
    >
      {children}
    </AuditContext.Provider>
  )
}

export const useAuditLogs = () => useContext(AuditContext)
