import React, { useState } from 'react'
import Image from 'next/image'
import logoDark from 'assets/imgs/dark-logo-horizontal.svg'
import logo from 'assets/imgs/negativo-logo-horizontal.svg'
import { Link } from 'components'
import { FiSettings, FiHelpCircle, FiUsers } from 'react-icons/fi'
import { IoDiamondOutline, IoExitOutline, IoList } from 'react-icons/io5'
import { BiTrophy } from 'react-icons/bi'
import { AiOutlineAudit } from 'react-icons/ai'
import { MdChatBubbleOutline, MdWebhook } from 'react-icons/md'

import { RiChatCheckLine, RiShieldCheckLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import SideBarOption from './side-bar-option'
import { CgFileDocument } from 'react-icons/cg'
import { VscKey } from 'react-icons/vsc'
import SideBarOrganizationOption from './side-bar-organization-option'
import SideBarDisclaimerOption from './side-bar-disclaimer-option'
import SideBarPlanCard from 'components/shared/cards/side-bar-plan-card'
import { useTheme } from 'contexts/theme-context'
import { paths } from 'config/paths'
import { classNames } from 'util/shared'
import { BsFillBarChartFill } from 'react-icons/bs'
import { useStorage } from 'contexts/storage-context'
import { useAuth } from 'contexts/auth-context'
import IconAdopt from 'assets/imgs/icons/adopt'
import { LuArrowLeftToLine, LuArrowRightToLine } from 'react-icons/lu'
import { SideBarAccordion } from './side-bar-accordion'
import { FaCode } from 'react-icons/fa6'
import { SideBarTooltip } from '../tooltip/sideBarTooltip'

export interface Link {
  name: string
  path: string
  subitem?: boolean
  target?: string
  icon: JSX.Element
}

export default function SideBar() {
  const { activeOrganizationDetails, activeDisclaimerPath } = useStorage()

  const [open, setOpen] = useState(true)

  const { theme } = useTheme()
  const { user } = useAuth()
  const { t } = useTranslation()

  function toggleSidebar() {
    setOpen(!open)
  }

  const iconSize = open ? 14 : 16

  const organizationLinks: Array<Link> = [
    {
      name: t('sideBar.myDisclaimers'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimers`
        : '#',
      icon: <MdChatBubbleOutline size={iconSize} />
    },
    {
      name: t('sideBar.documents'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/documents`
        : '#',
      icon: <CgFileDocument size={iconSize} />
    },
    ...(activeOrganizationDetails?.migrated_to_optout_v2
      ? [
          {
            name: t('sideBar.privacyPortal'),
            path: activeOrganizationDetails?.pathname
              ? `/org/${activeOrganizationDetails?.pathname}/privacy-portal`
              : '#',
            icon: <RiShieldCheckLine size={iconSize} />
          }
        ]
      : []),
    {
      name: t('sideBar.members'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/members`
        : '#',
      icon: <FiUsers size={iconSize} />
    },
    {
      name: t('sideBar.configuration'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/settings`
        : '#',
      icon: <FiSettings size={iconSize} />
    },
    {
      name: t('sideBar.dashboard'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/dashboard`
        : '#',
      icon: <BsFillBarChartFill size={iconSize} />
    },
    {
      name: t('sideBar.consentLogs'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/consent-log`
        : '#',
      icon: <IoList size={iconSize} />
    },

    {
      name: t('sideBar.audit'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/audit-log`
        : '#',
      icon: <AiOutlineAudit size={iconSize} />
    },
    {
      name: 'Webhooks',
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/webhooks`
        : '#',
      icon: <MdWebhook size={iconSize} />
    }
  ]

  const disclaimerLinks: Array<Link> = [
    {
      name: t('sideBar.style'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/style`
        : '#',
      subitem: true,
      icon: <RiChatCheckLine size={iconSize} />
    },

    {
      name: t('sideBar.documents'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/documents`
        : '#',
      subitem: true,
      icon: <CgFileDocument size={iconSize} />
    },

    activeOrganizationDetails?.migrated_to_optout_v2
      ? {
          name: t('sideBar.privacyPage'),
          path: activeOrganizationDetails?.pathname
            ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/requests-page`
            : '#',
          subitem: true,
          icon: <RiShieldCheckLine size={iconSize} />
        }
      : {
          name: t('sideBar.optOut'),
          path: activeOrganizationDetails?.pathname
            ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/opt-out`
            : '#',
          subitem: true,
          icon: <IoExitOutline size={iconSize} />
        },

    {
      name: t('sideBar.installation'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/installation`
        : '#',
      subitem: true,
      icon: <VscKey size={iconSize} />
    },
    {
      name: t('sideBar.tags'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}/tags`
        : '#',
      subitem: true,
      icon: <FaCode size={iconSize} />
    },
    {
      name: t('sideBar.configuration'),
      path: activeOrganizationDetails?.pathname
        ? `/org/${activeOrganizationDetails?.pathname}/disclaimer/${activeDisclaimerPath}`
        : '#',
      icon: <FiSettings size={iconSize} />
    }
  ]

  const bottomLinks: Array<Link> =
    user?.language === 'pt'
      ? [
          {
            name: t('sideBar.myPlan'),
            icon: <BiTrophy size={iconSize} />,
            path: paths.plan
          },
          {
            name: t('sideBar.help'),
            icon: <FiHelpCircle size={iconSize} />,
            path: paths.support.documents,
            target: '_blank'
          },
          {
            name: t('sideBar.referral'),
            icon: <IoDiamondOutline size={iconSize} />,
            path: paths.referrals
          }
        ]
      : [
          {
            name: t('sideBar.myPlan'),
            icon: <BiTrophy size={iconSize} />,
            path: paths.plan
          },
          {
            name: t('sideBar.help'),
            icon: <FiHelpCircle size={iconSize} />,
            path: paths.support.documents,
            target: '_blank'
          }
        ]

  return (
    <div className="relative select-none bg-white dark:bg-black">
      <div
        className={classNames(
          open ? 'w-64 ' : 'w-16',
          'duration-300 text-sm top-0 left-0 h-screen overflow-hidden hover:overflow-y-scroll scroll-1 bg-third-400 dark:bg-gray flex flex-col'
        )}
      >
        <div className="flex items-center justify-between p-4 border-b border-b-gray-400 border-opacity-40">
          {open ? (
            <>
              <Link
                className="outline-none relative h-10 w-32"
                href={paths.organizations}
              >
                <Image
                  src={theme === 'dark' ? logoDark : logo}
                  alt="Adopt logo"
                  layout="fill"
                  objectFit="contain"
                />
              </Link>
              <button
                className="text-white dark:text-gray-200"
                onClick={toggleSidebar}
              >
                <LuArrowLeftToLine size={16} />
              </button>
            </>
          ) : (
            <Link className="outline-none relative" href={paths.organizations}>
              <IconAdopt
                className="w-9 h-9 text-primary-200"
                fill={theme === 'dark' ? '#00DD80' : '#fff'}
              />
            </Link>
          )}
        </div>
        {!open && (
          <button
            className="p-1 absolute z-50 top-5 -right-3 rounded-full bg-shadow dark:bg-gray text-white dark:text-gray-200"
            onClick={toggleSidebar}
          >
            <LuArrowRightToLine size={16} />
          </button>
        )}
        <div className="flex flex-auto flex-col">
          <SideBarAccordion
            sideBarOpen={open}
            title={t('sideBar.upperOrganization')}
            fixedChildren={<SideBarOrganizationOption />}
          >
            <div data-tour="dashboard-step">
              {organizationLinks?.map((link, index) => (
                <div key={index} className="px-4 py-1">
                  <SideBarOption
                    index={index}
                    link={link}
                    open={open}
                  ></SideBarOption>
                </div>
              ))}
            </div>
          </SideBarAccordion>

          <div
            className={classNames(
              open ? 'w-56 mx-auto' : 'w-full',
              'h-[1px] bg-gray-400 opacity-40'
            )}
          />

          <SideBarAccordion
            sideBarOpen={open}
            title={t('sideBar.upperDisclaimer')}
            fixedChildren={<SideBarDisclaimerOption />}
          >
            {disclaimerLinks?.map((link, index) => (
              <div key={index} className="px-4 py-1">
                <SideBarOption
                  index={index}
                  link={link}
                  open={open}
                ></SideBarOption>
              </div>
            ))}
          </SideBarAccordion>
        </div>
        <div
          className={classNames(
            !open && 'border-t border-gray-400 border-opacity-40',
            'p-4'
          )}
        >
          {open && <SideBarPlanCard />}
          <div
            className={classNames(
              open ? 'justify-between' : 'flex-col gap-2',
              'flex mt-4 items-center text-white dark:text-gray-200'
            )}
          >
            {bottomLinks?.map((link, index) => (
              <SideBarTooltip key={index} content={link.name} open={open}>
                <Link
                  key={index}
                  href={link.path}
                  target={link.target}
                  className={classNames(
                    'flex',
                    open
                      ? 'gap-1 text-[10px]'
                      : 'p-2 dark:hover:text-primary hover:bg-third-600 dark:hover:bg-black rounded-lg duration-300'
                  )}
                >
                  {link.icon}
                  {open && link.name}
                </Link>
              </SideBarTooltip>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
