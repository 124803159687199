import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import i18next from 'i18next'

import {
  Accordion,
  Button,
  ButtonType,
  PagesHeader,
  SpinLoading
} from 'components'
import { AiOutlineAudit } from 'react-icons/ai'
import { FaRegEdit } from 'react-icons/fa'
import { IoColorPaletteOutline, IoList } from 'react-icons/io5'
import { Title } from 'components/pages/request-page/accordion/Title'
import { GeneralInfoContent } from 'components/pages/request-page/accordion/GeneralInfoContent'
import { TextsContent } from 'components/pages/request-page/accordion/TextsContent.tsx'
import { LegislationContent } from 'components/pages/request-page/accordion/LegislationContent'
import { CustomizationContent } from 'components/pages/request-page/accordion/CustomizationContent'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { Preview } from 'components/pages/request-page/preview'
import { LanguagesTypes, LegislationsTypes } from 'util/enums'
import { useHandleError } from 'hooks/useHandleError'
import { useRequestPage } from 'hooks/useRequestPage'
import {
  FormData,
  PageRequestById,
  Requests,
  TextData
} from 'models/request-page'
import { RequestsIframeModal } from 'components/shared/modal/requests-iframe-modal'
import appConfig from 'config/app-config'
import { paths } from 'config/paths'

type CreateRequestPageProps = {
  disclaimerId?: string
  requestPageId?: string
}

export default function CreateRequestPage({
  disclaimerId,
  requestPageId
}: CreateRequestPageProps) {
  const { defaultTexts } = useRequestPage()
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const { t } = useTranslation()
  const router = useRouter()
  const { handleError } = useHandleError()
  const { initialFormData, loading, requestPageById, addEditRequestsPage } =
    useRequestPage(requestPageId !== 'create' ? requestPageId : '')
  const { organizationPathname } = router.query

  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [accordionIndex, setAccordionIndex] = useState<number | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18next.language === 'en'
      ? LanguagesTypes.English
      : LanguagesTypes.Portuguese
  )
  const [selectedLegislation, setSelectedLegislation] = useState<string>(
    LegislationsTypes.LGPD
  )

  useEffect(() => {
    if (requestPageById) {
      fillFormData(
        requestPageById?.requests_page_master[0]?.requests_pages?.[0]
      )
      return
    }

    if (
      !requestPageById &&
      initialFormData.texts.length !== 0 &&
      initialFormData.requests.length !== 0
    ) {
      setFormData(initialFormData)
    }
  }, [initialFormData, requestPageById])

  function fillFormData(data: PageRequestById) {
    const formDataById: FormData = {
      name: data?.name,
      dpoUserId: data?.user_dpo_id,
      languages: data?.language_configuration,
      extraFields: data?.extra_fields,
      documents: {
        privacyMasterId:
          data?.requests_page_docs?.[0]?.document_master_privacy_id ?? '',
        cookiesMasterId:
          data?.requests_page_docs?.[0]?.document_master_cookies_id ?? '',
        termsMasterId:
          data?.requests_page_docs?.[0]?.document_master_terms_id ?? ''
      },
      requests: data?.requests_page_requests?.map(it => ({
        legislation: it?.legislation,
        requests: it?.requests
      })),
      texts: data?.requests_page_texts?.map(it => it.texts),
      style: data?.requests_page_styles?.[0].style
    }

    const updatedData = mergeChanges(initialFormData, formDataById)

    setFormData(updatedData)
  }

  function mergeChanges<T>(initialData: T, changedData: Partial<T>): T {
    const mergedData: any = Array.isArray(initialData)
      ? [...initialData]
      : { ...initialData }

    Object.keys(changedData).forEach(key => {
      const initialValue = (initialData as any)[key]
      const changedValue = (changedData as any)[key]

      if (changedValue === undefined) {
        return
      }

      if (Array.isArray(initialValue) && Array.isArray(changedValue)) {
        if (
          initialValue.every(item => typeof item === 'object') &&
          changedValue.every(item => typeof item === 'object')
        ) {
          mergedData[key] = initialValue.map((item, index) =>
            mergeChanges(item, changedValue[index] || {})
          )
        } else {
          mergedData[key] = [...changedValue]
        }
      } else if (
        typeof initialValue === 'object' &&
        initialValue !== null &&
        typeof changedValue === 'object' &&
        changedValue !== null
      ) {
        mergedData[key] = mergeChanges(initialValue, changedValue)
      } else {
        mergedData[key] = changedValue
      }
    })

    return mergedData
  }

  function handleAccordionClick(index: number) {
    setAccordionIndex(prevIndex => (prevIndex === index ? null : index))
  }

  function getChangedData(initialData: FormData, formData: FormData) {
    const result: Partial<FormData> = {}

    function getChanges<T>(initial: T, current: T): T | undefined {
      if (
        typeof initial !== 'object' ||
        initial === null ||
        initial === undefined
      ) {
        return initial !== current ? current : undefined
      }

      if (Array.isArray(initial) && Array.isArray(current)) {
        if (initial.length !== current.length) return current
        const changes = current
          .map((item, index) => getChanges(initial[index], item))
          .filter(Boolean)
        return changes.length > 0 ? (current as T) : undefined
      }

      const changes: any = {}
      for (const key in initial) {
        if (Object.prototype.hasOwnProperty.call(initial, key)) {
          const changed = getChanges(
            (initial as any)[key],
            (current as any)[key]
          )
          if (changed !== undefined) {
            changes[key] = changed
          }
        }
      }

      for (const key in current) {
        if (!(key in initial)) {
          changes[key] = (current as any)[key]
        }
      }

      return Object.keys(changes).length > 0 ? changes : undefined
    }

    const fieldsToCompare = [
      'name',
      'dpoUserId',
      'languages',
      'documents',
      'extraFields',
      'style'
    ] as const

    fieldsToCompare.forEach(field => {
      const changes = getChanges(initialData[field], formData[field])
      if (changes !== undefined) {
        ;(result as any)[field] = changes
      }
    })

    result.texts = formData.texts
      .map((text, index) => {
        const initialText = initialData.texts[index]
        const changes = getChanges(initialText, text)
        return changes !== undefined
          ? { ...changes, language: text.language }
          : null
      })
      .filter(Boolean) as TextData[]

    result.requests = formData.requests
      .map((request, index) => {
        const initialRequest = initialData.requests[index]
        const changes = getChanges(initialRequest, request)
        return changes
          ? { ...changes, legislation: request.legislation }
          : undefined
      })
      .filter(request => request !== undefined) as Requests[]

    if (result.texts?.length === 0) delete result.texts
    if (result.requests?.length === 0) delete result.requests

    return result
  }

  async function handleSave() {
    if (!isFormDataValid()) {
      return
    }

    const objWithChanges = getChangedData(initialFormData, formData)

    const payload = {
      ...objWithChanges,
      extraFields: objWithChanges.extraFields ?? formData.extraFields,
      style: JSON.stringify(objWithChanges.style ?? {})
    }

    toast.loading(t('createRequestPage.saving'))
    try {
      const result = await addEditRequestsPage(
        payload,
        disclaimerId,
        (requestPageId = requestPageId !== 'create' ? requestPageId : '')
      )

      if (result?.success) {
        toast.dismiss()
        toast.success(t('createRequestPage.success'))
        router.back()
      } else {
        toast.dismiss()
        handleError(result?.message)
      }
    } catch (error) {
      toast.dismiss()
      handleError()
    }
  }

  function isFormDataValid() {
    const isNameValid = !!formData.name

    const isDpoUserValid = !!formData.dpoUserId

    const isTextsValid = formData.texts.every(
      text => !!text.title && !!text.introduction
    )
    const isFontsValid = Object.values(formData.style.fonts).every(
      font => !!font.lineHeight && !!font.size
    )
    const isMarginsValid =
      !!formData.style.margins.horizontally &&
      !!formData.style.margins.vertically
    const isRoundedValid =
      !!formData.style.rounded.banner && !!formData.style.rounded.button

    const isRequestsValid = formData.requests.every(
      it => it.requests.length !== 0
    )

    if (!isNameValid) {
      handleError(t('createRequestPage.isNameValid'))
    }
    if (!isDpoUserValid) {
      handleError(t('createRequestPage.isDpoUserValid'))
    }
    if (!isTextsValid) {
      handleError(t('createRequestPage.isTextsValid'))
    }
    if (!isFontsValid) {
      handleError(t('createRequestPage.isFontsValid'))
    }
    if (!isMarginsValid) {
      handleError(t('createRequestPage.isMarginsValid'))
    }
    if (!isRoundedValid) {
      handleError(t('createRequestPage.isRoundedValid'))
    }
    if (!isRequestsValid) {
      handleError(t('createRequestPage.isRequestsValid'))
    }

    return (
      isNameValid &&
      isDpoUserValid &&
      isTextsValid &&
      isMarginsValid &&
      isRoundedValid
    )
  }

  function handleCancel() {
    setFormData(initialFormData)
    router.back()
  }

  const accordionData = [
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.generalInfo.title')}
          subTitle={t('createRequestPage.accordions.generalInfo.subTitle')}
          icon={<AiOutlineAudit />}
          isOpen={accordionIndex === 0}
        />
      ),
      content: <GeneralInfoContent {...{ formData, setFormData }} />
    },
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.texts.title')}
          subTitle={t('createRequestPage.accordions.texts.subTitle')}
          icon={<FaRegEdit />}
          isOpen={accordionIndex === 1}
        />
      ),
      content: (
        <TextsContent
          {...{ formData, setFormData, selectedLanguage, setSelectedLanguage }}
        />
      )
    },
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.legislation.title')}
          subTitle={t('createRequestPage.accordions.legislation.subTitle')}
          icon={<IoList />}
          isOpen={accordionIndex === 2}
        />
      ),
      content: (
        <LegislationContent
          {...{
            formData,
            setFormData,
            selectedLegislation,
            setSelectedLegislation
          }}
        />
      )
    },
    {
      title: (
        <Title
          title={t('createRequestPage.accordions.customization.title')}
          subTitle={t('createRequestPage.accordions.customization.subTitle')}
          icon={<IoColorPaletteOutline />}
          isOpen={accordionIndex === 3}
        />
      ),
      content: <CustomizationContent {...{ formData, setFormData }} />
    }
  ]

  return loading ? (
    <div className="h-screen flex justify-center items-center">
      <SpinLoading />
    </div>
  ) : (
    <>
      <PagesHeader
        title={t('createRequestPage.title')}
        subtitle={t('createRequestPage.subTitle')}
        page="optout"
        className="mb-5"
        button={
          requestPageId !== 'create' && (
            <Button
              onClick={() =>
                router.push(
                  paths.org.privacyPortal.requestPageVersionHistory(
                    organizationPathname as string,
                    requestPageId as string
                  )
                )
              }
              buttonType={ButtonType.Secondary}
            >
              {t('createRequestPage.restoreVersionsButton')}
            </Button>
          )
        }
      />

      <div className="px-10 grid xl:grid-cols-2 grid-cols-1 gap-8">
        <div className="flex flex-col gap-6">
          {accordionData.map(({ title, content }, index) => (
            <Accordion
              key={index}
              customTitle={title}
              isOpen={accordionIndex === index}
              onClick={() => handleAccordionClick(index)}
            >
              {content}
            </Accordion>
          ))}
        </div>
        <div>
          <Preview {...{ formData, selectedLanguage, selectedLegislation }} />
        </div>
      </div>

      <div className="pt-10 mt-8 mx-10 border-t border-gray-200 dark:border-gray-500 flex gap-4 justify-between">
        <Button
          buttonType={ButtonType.Outline}
          iconLeft={MdOutlineRemoveRedEye}
          onClick={() => setPreviewModalOpen(true)}
        >
          {t('createRequestPage.previewButton')}
        </Button>

        <div className="flex gap-4 items-center">
          <Button buttonType={ButtonType.Secondary} onClick={handleCancel}>
            {t('createRequestPage.cancelButton')}
          </Button>
          <Button onClick={handleSave}>
            {t('createRequestPage.saveButton')}
          </Button>
        </div>
      </div>
      <RequestsIframeModal
        open={previewModalOpen}
        handleClose={() => setPreviewModalOpen(false)}
        src={`${appConfig.hubUrl}/privacy-hub/preview`}
        messageBody={{
          ...formData,
          requests: formData.requests.find(
            request => request.legislation === selectedLegislation
          )?.requests,
          texts: defaultTexts.find(text => text.language === selectedLanguage)
            ?.texts
        }}
      />
    </>
  )
}
