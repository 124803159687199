import { Button, ButtonType } from 'components/shared/buttons'
import { Input } from 'components/shared/inputs'
import { Select } from 'components/shared/select/select'
import { ToggleSwitch } from 'components/shared/switch/switch'
import { H1, H2, H3, SmallText } from 'components/shared/typography'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { LanguagesModal } from '../languages-modal'
import { DocumentTypes } from 'components/shared/document-management'
import { useRequestPage } from 'hooks/useRequestPage'
import { AccordionContentProps, TextData } from 'models/request-page'
import { LanguageWithFlag } from 'components/shared/LanguageWithFlag'

interface TextsContentProps extends AccordionContentProps {
  selectedLanguage: string
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>
}

export const TextsContent = ({
  formData,
  setFormData,
  selectedLanguage,
  setSelectedLanguage
}: TextsContentProps) => {
  const { t } = useTranslation()
  const { privacyDocuments, cookieDocuments, termDocuments } = useRequestPage()

  const [languagesModalOpen, setLanguagesModalOpen] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([])

  useEffect(() => {
    if (formData.documents.privacyMasterId) {
      handleDocumentsToggle('privacy')
    }
    if (formData.documents.cookiesMasterId) {
      handleDocumentsToggle('cookies')
    }
    if (formData.documents.termsMasterId) {
      handleDocumentsToggle('terms')
    }
  }, [])

  function handleTextsChange(field: keyof TextData, value: string) {
    setFormData(prevData => ({
      ...prevData,
      texts: prevData.texts.map(text =>
        text.language === selectedLanguage ? { ...text, [field]: value } : text
      )
    }))
  }

  function handleDocumentsToggle(documentType: DocumentTypes) {
    const currentSelectedDocuments = [...selectedDocuments]
    const isSelectedDocumentExists = currentSelectedDocuments.find(
      type => type === documentType
    )

    const documentTypesMapper = {
      privacy: 'privacyMasterId',
      cookies: 'cookiesMasterId',
      terms: 'termsMasterId'
    }

    setSelectedDocuments(prev =>
      isSelectedDocumentExists
        ? prev.filter(type => type !== documentType)
        : [...prev, documentType]
    )

    if (isSelectedDocumentExists) {
      setFormData(prev => ({
        ...prev,
        documents: {
          ...prev.documents,
          [documentTypesMapper[documentType]]: ''
        }
      }))
    }
  }

  function handleExtraFieldsToggle(currentField: string) {
    const oldExtraFields = [...formData.extraFields]
    const newExtraFields = oldExtraFields.find(
      oldField => oldField === currentField
    )
      ? oldExtraFields.filter(oldField => oldField !== currentField)
      : [...oldExtraFields, currentField]
    setFormData(prev => ({ ...prev, extraFields: newExtraFields }))
  }

  const selectedText = formData.texts.find(
    text => text.language === selectedLanguage
  )

  return (
    <div className="flex flex-col gap-4 p-5 w-full">
      <H2>{t('createRequestPage.accordions.texts.textsLabel')}</H2>

      <div>
        <SmallText className="dark:!text-gray-250 mb-1">
          {t('createRequestPage.accordions.texts.language')}
        </SmallText>
        <div className="flex gap-4 items-center">
          <Select
            containerClasses={'w-full'}
            params={formData.languages.languages.map((language: string) => ({
              name: <LanguageWithFlag languageCode={language} />,
              value: language
            }))}
            value={selectedLanguage}
            onChange={e => {
              setSelectedLanguage(e.value as string)
            }}
          />
          <Button
            buttonType={ButtonType.Secondary}
            onClick={() => setLanguagesModalOpen(true)}
          >
            {t('createRequestPage.accordions.texts.languageDefaultButton')}
          </Button>
        </div>
      </div>

      <div>
        <SmallText className="dark:!text-gray-250 mb-1">
          {t('createRequestPage.accordions.texts.titleLabel')}
        </SmallText>
        <Input
          maxLength={50}
          value={selectedText?.title ?? ''}
          events={{
            onChange: (e: any) => {
              handleTextsChange('title', e.value)
            }
          }}
        />
      </div>

      <div>
        <SmallText className="dark:!text-gray-250 mb-1">
          {t('createRequestPage.accordions.texts.introductionLabel')}
        </SmallText>
        <Input
          type={'textarea'}
          maxLength={1000}
          value={selectedText?.introduction ?? ''}
          events={{
            onChange: (e: any) => {
              handleTextsChange('introduction', e.value)
            }
          }}
        />
      </div>

      <div>
        <H2>{t('createRequestPage.accordions.texts.documents')}</H2>
        <Input
          maxLength={50}
          value={selectedText?.docsTitle ?? ''}
          events={{
            onChange: (e: any) => {
              handleTextsChange('docsTitle', e.value)
            }
          }}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center gap-4">
          <ToggleSwitch
            state={selectedDocuments.includes('privacy')}
            setState={() => handleDocumentsToggle('privacy')}
          />
          <H3 fontWeight="bold">
            {t('createRequestPage.accordions.texts.privacyPolicy')}
          </H3>
        </div>
        {selectedDocuments.includes('privacy') && (
          <Select
            params={privacyDocuments ?? []}
            value={formData.documents.privacyMasterId}
            onChange={e => {
              if (!e.value) return
              setFormData(prev => ({
                ...prev,
                documents: {
                  ...prev.documents,
                  privacyMasterId: e.value as string
                }
              }))
            }}
          />
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center gap-4">
          <ToggleSwitch
            state={selectedDocuments.includes('cookies')}
            setState={() => handleDocumentsToggle('cookies')}
          />
          <H3 fontWeight="bold">
            {t('createRequestPage.accordions.texts.cookiePolicy')}
          </H3>
        </div>
        {selectedDocuments.includes('cookies') && (
          <Select
            params={cookieDocuments ?? []}
            value={formData.documents.cookiesMasterId}
            onChange={e => {
              if (!e.value) return
              setFormData(prev => ({
                ...prev,
                documents: {
                  ...prev.documents,
                  cookiesMasterId: e.value as string
                }
              }))
            }}
          />
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center gap-4">
          <ToggleSwitch
            state={selectedDocuments.includes('terms')}
            setState={() => handleDocumentsToggle('terms')}
          />
          <H3 fontWeight="bold">
            {t('createRequestPage.accordions.texts.termsPolicy')}
          </H3>
        </div>
        {selectedDocuments.includes('terms') && (
          <Select
            params={termDocuments ?? []}
            value={formData.documents.termsMasterId}
            onChange={e => {
              if (!e.value) return
              setFormData(prev => ({
                ...prev,
                documents: {
                  ...prev.documents,
                  termsMasterId: e.value as string
                }
              }))
            }}
          />
        )}
      </div>

      <H1>{t('createRequestPage.accordions.texts.optionalFields')}</H1>
      <div className="flex items-center gap-4">
        <ToggleSwitch
          state={formData.extraFields.includes('name')}
          setState={() => handleExtraFieldsToggle('name')}
        />
        <H3 fontWeight="bold">
          {t('createRequestPage.accordions.texts.completeName')}
        </H3>
      </div>
      <div className="flex items-center gap-4">
        <ToggleSwitch
          state={formData.extraFields.includes('country')}
          setState={() => handleExtraFieldsToggle('country')}
        />
        <H3 fontWeight="bold">
          {t('createRequestPage.accordions.texts.originCountry')}
        </H3>
      </div>
      <div className="flex items-center gap-4">
        <ToggleSwitch
          state={formData.extraFields.includes('obs')}
          setState={() => handleExtraFieldsToggle('obs')}
        />
        <H3 fontWeight="bold">
          {t('createRequestPage.accordions.texts.observations')}
        </H3>
      </div>

      {languagesModalOpen && (
        <LanguagesModal
          languagesModalOpen={languagesModalOpen}
          setLanguagesModalOpen={setLanguagesModalOpen}
          formData={formData}
          setFormData={setFormData}
          selectedLanguage={selectedLanguage}
        />
      )}
    </div>
  )
}
