import React, { useMemo } from 'react'
import { BsImageAlt } from 'react-icons/bs'
import { HiOutlineThumbUp, HiOutlineThumbDown } from 'react-icons/hi'
import { FiSettings } from 'react-icons/fi'
import { DiCode } from 'react-icons/di'
import { RiChatCheckLine } from 'react-icons/ri'
import { CgFileDocument } from 'react-icons/cg'
import { VscKey } from 'react-icons/vsc'
import { motion } from 'framer-motion'
import { Link, Tooltip } from 'components'
import StatusBadge from '../../../shared/badge/status-badge'
import { classNames } from 'util/shared'
import { useTranslation } from 'react-i18next'
import { IoExitOutline } from 'react-icons/io5'
import DisclaimerEditDropdown from '../../../shared/dropdown/disclaimer-edit-dropdown'
import { formatNumber } from 'util/format'
import { useStorage } from 'contexts/storage-context'
import {
  DisclaimerData,
  DisclaimerInstalledStatusColor
} from 'models/disclaimer'

const easing = [0.6, -0.05, 0.01, 0.99]
const fadeInUp = {
  initial: {
    y: 10,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: easing
    }
  }
}

interface DisclaimerCardProps {
  index: number
  disclaimerData: DisclaimerData
  refetch: () => void
}

export default function DisclaimerCard({
  index,
  disclaimerData,
  refetch
}: DisclaimerCardProps) {
  const { t } = useTranslation()
  const { activeOrganizationDetails } = useStorage()

  const hasPrivacyPolicy = disclaimerData?.disclaimer_documents?.[0]
    ?.documentMasterByDocumentMasterPrivacyId
    ? 1
    : 0
  const hasCookiePolicy = disclaimerData?.disclaimer_documents?.[0]
    ?.document_master
    ? 1
    : 0
  const hasTermsPolicy = disclaimerData?.disclaimer_documents?.[0]
    ?.documentMasterByDocumentMasterTermsId
    ? 1
    : 0

  const hasDocuments =
    hasPrivacyPolicy + hasCookiePolicy + hasTermsPolicy === 3
      ? 'completed'
      : hasPrivacyPolicy + hasCookiePolicy + hasTermsPolicy > 0
      ? 'partial_completed'
      : 'not_completed'

  const disclaimerInstalledStatus = useMemo(() => {
    const { disclaimer_websites } = disclaimerData
    const allDisclaimersInstalled = disclaimer_websites.length
    let disclaimerInstalled = 0
    let disclaimerNotInstalled = 0
    for (const { disclaimer_installed } of disclaimer_websites) {
      if (disclaimer_installed) disclaimerInstalled++
      else disclaimerNotInstalled++
    }

    if (disclaimerInstalled === allDisclaimersInstalled) {
      return 'completed'
    } else if (disclaimerNotInstalled === allDisclaimersInstalled) {
      return 'not_completed'
    } else {
      return 'partial_completed'
    }
  }, [disclaimerData])

  const statusColorMap: DisclaimerInstalledStatusColor = {
    completed: 'bg-primary text-white',
    partial_completed: 'bg-transparent border border-yellow text-yellow',
    not_completed: 'bg-transparent border border-pink text-pink'
  }

  const items = [
    {
      configs: [
        {
          dataTestId: 'styleStep',
          icon: <RiChatCheckLine size={16} />,
          name: t('org.disclaimers.style'),
          tooltip: t('org.disclaimers.style-tooltip'),
          pathname: 'style',
          status:
            disclaimerData?.disclaimer_versions[0]?.disclaimer_style_version ===
            0
              ? 'not_completed'
              : 'completed'
        }
      ]
    },
    {
      tourStep: 'documents-step',
      configs: [
        {
          dataTestId: 'termsStep',
          icon: <CgFileDocument size={18} />,
          name: t('org.disclaimers.documents'),
          tooltip: t('org.disclaimers.documents-tooltip'),
          pathname: 'documents',
          status: hasDocuments
        },
        activeOrganizationDetails?.migrated_to_optout_v2
          ? {
              dataTestId: 'privacyPageStep',
              icon: <IoExitOutline size={18} />,
              name: t('org.disclaimers.privacyPage'),
              tooltip: t('org.disclaimers.privacyPage-tooltip'),
              pathname: 'requests-page',
              status:
                disclaimerData?.disclaimer_versions[0]?.request_page_version ===
                0
                  ? 'not_completed'
                  : 'completed'
            }
          : {
              dataTestId: 'optoutStep',
              icon: <IoExitOutline size={18} />,
              name: t('org.disclaimers.optout'),
              tooltip: t('org.disclaimers.optout-tooltip'),
              pathname: 'opt-out',
              status:
                disclaimerData?.disclaimer_versions[0]?.opt_out_version === 0
                  ? 'not_completed'
                  : 'completed'
            }
      ]
    },

    {
      tourStep: 'tag-step',
      configs: [
        {
          dataTestId: 'tagStep',
          icon: <VscKey size={18} />,
          name: t('org.disclaimers.installation'),
          tooltip: t('org.disclaimers.installation-tooltip'),
          pathname: 'installation',
          status: disclaimerInstalledStatus
        }
      ]
    },
    {
      tourStep: 'scan-step',
      configs: [
        {
          dataTestId: 'scanStep',
          icon: <DiCode size={24} />,
          name: t('org.disclaimers.scan'),
          tooltip: t('org.disclaimers.scan-tooltip'),
          pathname: 'tags',
          status:
            disclaimerData?.disclaimer_versions?.[0]?.tags_version === 0
              ? 'not_completed'
              : 'completed'
        }
      ]
    }
  ]
  return (
    <motion.div
      exit={{ opacity: 0 }}
      variants={fadeInUp}
      key={index}
      data-tour="intro-step"
      data-test-id={'disclaimerCard'}
      className="flex-1 w-full px-3 py-3 mb-5 duration-200 border border-gray-200 rounded-lg max-w-100 lg:max-w-49 lg:w-2/4 lg:min-w-49 dark:border-gray-400 hover:border-primary"
    >
      <div className="flex gap-2 ">
        <Link
          className="flex items-center flex-1 gap-2 truncate pb-4"
          href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}`}
        >
          <div className="flex items-center justify-center border border-gray-200 rounded-full w-9 h-9 dark:bg-gray text-primary-400">
            <BsImageAlt />
          </div>
          <div className="flex-1 truncate">
            <div className="font-bold truncate dark:text-primary-400">
              {disclaimerData?.pathname}
            </div>
            <div className="truncate dark:text-gray-300">
              {disclaimerData?.disclaimer_websites?.map(
                (domain: any, index: number) => {
                  return `${domain?.url}${
                    index ===
                    Object.keys(disclaimerData?.disclaimer_websites)?.length - 1
                      ? ''
                      : ', '
                  }`
                }
              )}
            </div>
          </div>
          <div className="flex items-center dark:text-white">
            <StatusBadge
              status={disclaimerData?.disclaimer_status?.name}
            ></StatusBadge>
            <div className="ml-1 capitalize">
              {t(`org.disclaimers.${disclaimerData?.disclaimer_status?.name}`)}
            </div>
          </div>
        </Link>
        <div className="mt-2">
          <DisclaimerEditDropdown
            availableItems={[
              'configure',
              'edit',
              'replicate',
              'transfer',
              'copyToClipboard',
              'block',
              'delete'
            ]}
            refetch={refetch}
            disclaimerData={disclaimerData}
            position="left"
          >
            <div className="flex items-center justify-center w-8 h-8 border rounded border-primary-400 text-primary-400">
              <FiSettings size={18} />
            </div>
          </DisclaimerEditDropdown>
        </div>
      </div>

      <div data-tour="initialConfiguration-step" className="flex">
        {items?.map((item, index) => {
          return (
            <div className="flex" data-tour={item.tourStep} key={index}>
              {item.configs.map((conf, index) => {
                return (
                  <Link
                    data-test-id={conf.dataTestId}
                    className={`step-${index}`}
                    key={index}
                    href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}/${conf.pathname}`}
                  >
                    <Tooltip size="Auto" content={conf.tooltip}>
                      <div
                        data-delay-show="40"
                        key={index}
                        className="flex flex-wrap items-center justify-center duration-200 cursor-pointer dark:text-white opacity-80 hover:opacity-100"
                      >
                        <div
                          className={classNames(
                            'w-8 h-8  flex justify-center items-center rounded-full mr-1 ml-1',
                            statusColorMap[conf.status]
                          )}
                        >
                          {conf.icon}
                        </div>
                        <div className="hidden text-xs truncate lg:block">
                          {conf.name}
                        </div>
                      </div>
                    </Tooltip>
                  </Link>
                )
              })}
            </div>
          )
        })}
        <div className=" w-full flex-1">
          <Link
            className="flex w-full h-full items-center flex-1"
            href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}`}
          ></Link>
        </div>
      </div>
      <Link
        href={`/org/${activeOrganizationDetails?.pathname}/disclaimer/${disclaimerData?.pathname}`}
      >
        <div className="flex items-center pt-6 ">
          <div className="flex items-center text-primary-400">
            <HiOutlineThumbUp className="" />
            <span className="mx-1 text-gray-200">Opt-in</span>
            <span>+ {formatNumber(disclaimerData?.metrics?.opt_ins || 0)}</span>
          </div>
          <div className="flex items-center ml-6 text-primary-400">
            <HiOutlineThumbDown style={{ color: '#EE447B' }} />
            <span className="mx-1 text-gray-200">Opt-out</span>
            <span style={{ color: '#EE447B' }}>
              - {formatNumber(disclaimerData?.metrics?.opt_out || 0)}
            </span>
          </div>
        </div>
      </Link>
    </motion.div>
  )
}
